<template>
  <div id="guidePage">
    <div class="header-nav flex-align">
      <img :src="themeData.darkLogoList">
      <div class="nav-right flex-align" @click="languageChange">
        <global-outlined class="ic-lanage" />
        <span class="text">{{ language === 'zh' ? 'English' : '中文' }}</span>
      </div>
    </div>
    <div class="body-info">
      <div style="display: flex; width: 988px;">
        <div class="body-left">
          <div class="top-img">
            <img src="~@/assets/images/guide-page.png">
          </div>
          <div class="top-title">{{ $t('pages_merchant_107') }}</div>
          <div class="top-explain">{{ $t('pages_merchant_110') }}</div>
          <a-timeline style="margin-top: 36px">
            <a-timeline-item>
              <div class="timeline-title">{{ $t('pages_merchant_111') }}</div>
              <div>{{ $t('pages_merchant_112') }}</div>
            </a-timeline-item>
            <a-timeline-item>
              <div class="timeline-title">{{ $t('pages_merchant_113') }}</div>
              <div>{{ $t('pages_merchant_114') }}</div>
            </a-timeline-item>
            <a-timeline-item>
              <div class="timeline-title">{{ $t('pages_merchant_115') }}</div>
              <div>{{ $t('pages_merchant_116') }}</div>
            </a-timeline-item>
          </a-timeline>
          <a-button
            type="primary"
            style="margin-top: 36px"
            @click="$router.push('/merchant_info')"
          >{{ $t('pages_merchant_117') }}</a-button>
        </div>
        <div class="body-right">
          <div class="title">{{ $t('pages_merchant_118') }}</div>
          <div class="area">
            <div class="area-left"><img src="~@/assets/images/business-license.png"></div>
            <div class="area-right">
              <div class="right-title">{{ $t('pages_merchant_119') }}</div>
              <div class="right-explain">{{ $t('pages_merchant_120') }}</div>
              <a class="right-down" :href="require('../../assets/files/yingYeZhiZhao.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
            </div>
          </div>
          <div class="area">
            <div class="area-left"><img src="~@/assets/images/id-card.png"></div>
            <div class="area-right">
              <div class="right-title">{{ $t('pages_merchant_121') }}</div>
              <div class="right-explain">{{ $t('pages_merchant_122') }}</div>
              <div class="right-explain">{{ $t('pages_merchant_123') }}</div>
              <div class="right-explain">{{ $t('pages_merchant_124') }}</div>
              <a class="right-down" :href="require('./../../assets/files/shenFenZheng.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
            </div>
          </div>
          <div class="area">
            <div class="area-left"><img src="~@/assets/images/certificate-authorization.png"></div>
            <div class="area-right">
              <div class="right-title">{{ $t('pages_merchant_125') }}</div>
              <div class="right-explain">{{ $t('pages_merchant_126') }}</div>
              <a v-if="language === 'en'" class="right-down" @click="downloadTemplate('boardResolution')">{{ $t('pages_merchant_046') }}</a>
              <a v-else class="right-down" @click="downloadTemplate('powerAttorney')">{{ $t('pages_merchant_046') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject } from 'vue'
import { useStore } from 'vuex'
import { _user } from '@/api'
export default {
  name: 'GuidePage',
  setup () {
    const store = useStore()
    const state = reactive({
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh'
    })

    const languageChange = async () => {
      localStorage.setItem('locale-language', state.language === 'zh' ? 'en' : 'zh')
      await store.dispatch('getLanguage')
      location.reload()
    }

    const downloadTemplate = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }

    return {
      languageChange,
      downloadTemplate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#guidePage {
  text-align: left;
  color: #000;
  .header-nav {
    height: 60px;
    padding: 0 24px;
    margin-bottom: 4px;
    box-shadow: 0 0 6px 0 #d9d9d9;
    img {
      width: 150px;
    }
    .nav-right {
      margin-left: auto;
      cursor: pointer;
      .ic-lanage {
        color: @main-color;
        font-size: 24px;
      }
      .text {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .body-info {
    display: flex;
    justify-content: center;
    padding: 36px 0;
    .body-left {
      .top-img {
        img {
          width: 280px;
        }
      }
      .top-title {
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
      .top-explain {
        color: #495060;
        margin-top: 12px;
      }
      .timeline-title {
        font-weight: 600;
        margin-bottom: 7px;
      }
    }
    .body-right {
      width: 100%;
      margin-left: 188px;
      box-shadow: 0 0 6px 0 #d9d9d9;
      border-radius: 12px;
      padding: 24px 36px;
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      .area {
        margin-top: 64px;
        display: flex;
        .area-left {
          img {
            width: 60px;
          }
        }
        .area-right {
          margin-left: 36px;
          .right-title {
            font-weight: 600;
          }
          .right-explain {
            color: #495060;
            margin-top: 5px;
          }
          .right-down {
            display: inline-block;
            margin-top: 5px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>

